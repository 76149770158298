import React from "react";
import StatsContext, { StatsContextType } from "../StatsContext";
import dayjs, { Dayjs } from "dayjs";
import GameView from "./gameView/GameView";
import { Event, getEventDefinition } from "../type/Event";
import { gapi } from "gapi-script";
// import GoogleAuth from "./googleApi/GoogleAuth";
// import FileHandler from "./googleApi/FileHandler";
import FileUploading from "../hooks/FileUploading";
import Ideas from "./Ideas";
import Footer from "./footer";

function LoadView() {
    const props = React.useContext(StatsContext) as StatsContextType;
    const {uploadFile, isUploading} = FileUploading();
    const [file, setFile] = React.useState<File | null>(null);
    // Array of API discovery doc URLs for APIs
    const DISCOVERY_DOCS = ['https://www.googleapis.com/discovery/v1/apis/drive/v3/rest'];

    const [accessToken, setAccessToken] = React.useState<any>('');

    const [uploaded, setUploaded] = React.useState<boolean>(false);

    // Authorization scopes required by the API; multiple scopes can be
    // included, separated by spaces.
    const SCOPES = 'https://www.googleapis.com/auth/drive https://www.googleapis.com/auth/docs https://www.googleapis.com/auth/drive.metadata';

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = event.target.files && event.target.files[0];
        setFile(selectedFile);
    };

    const loadFile = (cleanup: boolean) => {
        const reader = new FileReader();
        reader.onload = function(event) {
            try {
                const content: string = event.target!.result as string;
                let data: any = JSON.parse(content);
                props.loadFile(data, cleanup);
                props.setCurrentModalView(null);
                props.setCurrentView(GameView.VIEW_NAME);    
            } catch (e) {
                window.alert("Plik jest uszkodzony");
                console.log(e);
            }
        };

        if (file !== null) {
            reader.readAsText(file);
        }
    }

    const startNewCleanProject = () => {
        //props.setEvents([]);
        //props.setPlayers([]);
        let pls = [...props.players];
        for (let i = 0; i < pls.length; i++) {
            pls[i].onField = false;
        }
        props.setPlayers(pls);
        props.setActions([]);
        props.setMatchStart(null);
        props.setFirstHalfEnd(null);
        props.setSecondHalfStart(null);
        props.setMatchEnd(null);

        props.setCurrentView(GameView.VIEW_NAME);
        props.setCurrentModalView(null);
        props.createNewGameId();
    }

    
    const createGameName = () => {
        let date: Dayjs = props.matchStart?? dayjs();
        return "mecz_" + date.format("YYYY-MM-DD-HHmm");
    }

    const saveCSV = () => {
        let sep = ",";
        let exp = "Czas" + sep + "Zawodnik" + sep + "Nr Połowy" + sep + "Nazwa\n";
        
        let commonActions = props.actions;
        for (let i = 0; i < commonActions.length; i++) {
            let act: any = commonActions[i];
            let tMs: number;
            let halfNo;
            if (props.matchStart !== null && act.time > props.matchStart) {
                if (props.firstHalfEnd === null || props.firstHalfEnd !== null && act.time < props.firstHalfEnd) {
                    halfNo = 1;
                    tMs = act.time.diff(props.matchStart, "millisecond");
                } else {
                    halfNo = 2;
                    tMs = act.time.diff(props.secondHalfStart, "millisecond");
                }
                var minutes = Math.floor(tMs / (1000 * 60)); // minutes
                var seconds = Math.floor((tMs/1000) - minutes*60);
                exp += minutes + ":" + seconds + sep + (act.player ?? "") + sep + halfNo + sep + act.name;
                let eventDef: Event|undefined = getEventDefinition(props, act.name);
                if (eventDef !== undefined && eventDef.property !== undefined && eventDef.property.length > 0) {
                    for (let j = 0; j < eventDef.property.length; j++) {
                        let prop = act.properties["prop" + j];
                        if (prop === undefined) {
                            prop = "";
                        }
                        exp += sep + prop;
                    }
                }
                
                exp += "\n";
            }
        }

        var encoder = new TextEncoder();
        var encodedData = encoder.encode(exp).buffer;
        downloadFile(encodedData, 'text/csv', createGameName() + '.csv');
    }

    const createJsonFile = (): string => {
        let toBeSaved: any = {};
        toBeSaved.saveVersion = props.version;
        toBeSaved.matchStart = props.matchStart;
        toBeSaved.halfEnd = props.firstHalfEnd;
        toBeSaved.sndHalfStart = props.secondHalfStart;
        toBeSaved.matchEnd = props.matchEnd;
        toBeSaved.players = props.players;
        toBeSaved.events = props.events;
        toBeSaved.actions = props.actions;
        toBeSaved.gameId = props.gameId;
        toBeSaved.reports = props.reports;

        return JSON.stringify(toBeSaved);
    }
    const save = () => {
        var encoder = new TextEncoder();
        var encodedData = encoder.encode(createJsonFile()).buffer;
        downloadFile(encodedData, 'application/json', createGameName() + '.json');
    }

    const uploadToServer = () => {
        let jsonData = createJsonFile();
        uploadFile({jsonData: jsonData, gameId: props.gameId == "" ? createGameName() : props.gameId});
        setUploaded(true);
    }

    const downloadFile = (data: any, type: string, name: string) => {
        var dataToSave = new Blob([data], { type: type});
        var file = new File([dataToSave], name);
        var url = URL.createObjectURL(file);
        var link = document.createElement('a');
        link.href = url;
        link.download = file.name;
    
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const makeRequest = () => {
        var request = gapi.client.drive.files.list({'maxResults': 5 });
        request.execute((resp: any) => {
            console.log(resp);          
            // for (let i = 0; i < resp.items.length; i++) {
            //     var titulo = resp.items[i].title;
            //     var fechaUpd = resp.items[i].modifiedDate;
            //     var userUpd = resp.items[i].lastModifyingUserName;
            //     var userEmbed = resp.items[i].embedLink;
            //     var userAltLink = resp.items[i].alternateLink;

            //     console.log(resp.items[i]);
            // }
        });    
    }

    const googleTest2 = () => {
        gapi.load('client', () => {
            gapi.client.setApiKey(process.env.REACT_APP_GOOGLE_DRIVE_API_KEY);
            window.setTimeout(() => {
                gapi.auth.authorize({client_id: process.env.REACT_APP_GOOGLE_DRIVE_CLIENT_ID, scope: SCOPES, immediate: true},
                    (authResult: any) => {
                        if (authResult && !authResult.error) {
                            console.log("Not authorized");
                            console.log(authResult);
                        } else {
                            gapi.client.load('drive', 'v2', makeRequest);  
                        };
                    }
                );
            }, 1);
        });
    }

    let link = "https://funit.com.pl/stats/?game=" + props.gameId;

    return <div className="bigButtonsDiv">
        <h1>Achista - Statystyki gry</h1>
        version 0.{props.version}
        <div>
            Wczytaj: 
            <input type="file" onChange={handleFileChange}/>
            <button onClick={e => loadFile(false)} disabled={file===null}><b>Wczytaj wszystkie dane meczu</b></button>
            <button onClick={e => loadFile(true)} disabled={file===null}><b>Wczytaj dane pod nowy mecz</b><br></br>(tylko lista zawodników i wydarzeń)</button>
        </div>
        <div>
            Zapisz:
            <button onClick={e => saveCSV()}><b>pobierz CSV</b><br></br> (np. dla Excel'a lub Spreadsheets)</button>
            <button onClick={e => save()}><b>Zapisz lokalnie</b><br></br> (pobierz plik)</button>
            <button onClick={e => uploadToServer()}><b>Zapisz na serwerze</b><br></br> </button>
            {uploaded && !isUploading && <p>
                Zapisano na serwerze. Link: <a href={link}>{link}</a>
            </p>}
            {isUploading && <p>Zapisywanie na serwerze...</p>}
        </div>
        {props.currentView !== LoadView.VIEW_NAME && <div>
            <button onClick={e => props.setCurrentModalView(null)}>Anuluj/Wróć</button>
        </div>}
        {props.currentView === LoadView.VIEW_NAME && <div>
            <button onClick={e => startNewCleanProject()}><b>Nowy mecz</b><br></br>(Zachowaj listę zawodników i definicję akcji)</button>
        </div>}
        <hr></hr>
        <button onClick={e => props.setCurrentView(Ideas.VIEW_NAME)}>Mniej lub bardziej rozsądne pomysły na rozwój programu</button>
        {/* <div>
            <button onClick={e => googleTest2()}>Guzik testowy</button>
        </div>
        <div> */}
        {/* <GoogleAuth setAccessToken={setAccessToken} />
            {accessToken && <FileHandler accessToken={accessToken} />}
        </div> */}
        {/* <Exmpl></Exmpl> */}
    </div>
}
LoadView.VIEW_NAME = "LoadView";
export default LoadView;