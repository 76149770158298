import EventsColumn from "./EventsColumn";
import StatisticsColumn from "./StatisticsColumn";

function TeamRow() {
    return <tr>
        <td>Zespół</td>
        <EventsColumn isWholeTeamColumn={true} playerId="team"></EventsColumn>
        <StatisticsColumn isWholeTeamColumn={true}></StatisticsColumn>
    </tr>
}
export default TeamRow;