import React from "react";
import StatsContext, { StatsContextType } from "../../StatsContext";
import { Event, createEvent } from "../../type/Event";
import { EventProperty } from "../../type/EventProperty";
import './EditEvents.css'
import styled from "styled-components";
import PassEventEdit from "./PassEventEdit";

function EditEvents() {
    const props = React.useContext(StatsContext) as StatsContextType;

    const addEvent = (event: Event) => {
        props.setEvents([...props.events, event]);
    }

    const changeEventName = (index: number, newName: string) => {
        console.log(props.events);
        let evs = [...props.events];
        evs[index] = {...props.events[index], name: newName};
        props.setEvents(evs);
    }

    const isEntry = (index: number, check: boolean) => {
        let evs = [...props.events];
        evs[index] = {...props.events[index], isEntry: check};
        props.setEvents(evs);
    }

    const isExit = (index: number, check: boolean) => {
        let evs = [...props.events];
        evs[index] = {...props.events[index], isExit: check};
        props.setEvents(evs);
    }

    const switchProperty = (index: number, property: keyof Event , check: boolean) => {
        let evs = [...props.events];
        evs[index] = {...props.events[index], [property]: check};
        props.setEvents(evs);
    }

    const move = (index: number, moveUp: boolean) => {
        if (index === 0 && moveUp) return;
        if (index === props.events.length-1 && !moveUp) return;
        let evs = [...props.events];
        let el = evs[index];
        evs.splice(index, 1);
        evs.splice(index + (moveUp ? -1 : 1), 0, el);
        props.setEvents(evs);
    }
    const remove = (index: number) => {
        let evs = [...props.events];
        evs.splice(index, 1);
        props.setEvents(evs);
    }
    const changeTeamLevel = (index: number, isTeamLevel: boolean) => {
        let evs = [...props.events];
        evs[index] = {...props.events[index], teamLevel: isTeamLevel};
        props.setEvents(evs);
    }
    const changeHalfEnabled = (index: number, firstHalf: boolean, enable: boolean) => {
        let evs = [...props.events];
        if (firstHalf) {
            evs[index] = {...props.events[index], firstHalf: enable};
        } else {
            evs[index] = {...props.events[index], secondHalf: enable};
        }
        props.setEvents(evs);
    }
    const addEventProperty = (index: number) => {
        let evs = [...props.events];
        evs[index].property = [...evs[index].property, []];
        props.setEvents(evs);
    }
    const removeProperty = (eventNo: number, propertyIndex: number) => {
        let evs = [...props.events];
        evs[eventNo].property = [... evs[eventNo].property];
        evs[eventNo].property.splice(propertyIndex, 1);
        props.setEvents(evs);
    }
    const changePropertyValue = (eventNo: number, propertyIndex: number, propValueIndex: number, newValue: string) => {
        let evs = [...props.events];
        evs[eventNo].property = [... evs[eventNo].property];
        evs[eventNo].property[propertyIndex] = [...evs[eventNo].property[propertyIndex]];
        evs[eventNo].property[propertyIndex][propValueIndex] = {... evs[eventNo].property[propertyIndex][propValueIndex], name: newValue};
        props.setEvents(evs);
    }
    const moreProps = (index: number, propertyIndex: number) => {
        let evs = [...props.events];
        evs[index].property = [...evs[index].property];
        evs[index].property[propertyIndex] = [...evs[index].property[propertyIndex], {name: ""}];
        props.setEvents(evs);
    }
    const lessProps = (index: number, propertyIndex: number) => {
        let evs = [...props.events];
        evs[index].property = [...evs[index].property];
        evs[index].property[propertyIndex] = [...evs[index].property[propertyIndex]];
        evs[index].property[propertyIndex].splice(evs[index].property[propertyIndex].length-1, 1);
        props.setEvents(evs);
    }

    let passEnabled: boolean = props.events.filter(e => e.pass != undefined).length > 0;

    return <div id="actionEditorDiv">
        <br></br>
        <div id="actionList">
            <PassEventEdit></PassEventEdit>
            {props.events.map((ev: Event, index: number) => <React.Fragment key={"event_" + index}>
                {!ev.pass && <ActionElement>
                    <input value={ev.name ?? ""} placeholder="Podaj nazwę zdarzenia" onChange={e => changeEventName(index, e.target.value)}/>
                    <label><input type="checkbox" checked={ev.teamLevel?? false} onChange={e => changeTeamLevel(index, e.target.checked)}/>Zdarzenie ogólnozespołowe</label>
                    <label><input type="checkbox" checked={ev.firstHalf} onChange={e => changeHalfEnabled(index, true, e.target.checked)}/>I Połowa</label>
                    <label><input type="checkbox" checked={ev.secondHalf} onChange={e => changeHalfEnabled(index, false, e.target.checked)}/>II Połowa</label>
                    <label><input type="checkbox" checked={ev.isEntry ?? false} onChange={e => isEntry(index, e.target.checked)}/>Wejście na boisko</label>
                    <label><input type="checkbox" checked={ev.isExit ?? false} onChange={e => isExit(index, e.target.checked)}/>Zejście z boiska</label>
                    <label><input type="checkbox" checked={ev.getTheBall ?? false} onChange={e => switchProperty(index, "getTheBall", e.target.checked)} disabled={!passEnabled}/>Uzyskanie piłki</label>
                    <label><input type="checkbox" checked={ev.looseTheBall ?? false} onChange={e => switchProperty(index, "looseTheBall", e.target.checked)}  disabled={!passEnabled}/>Oddanie piłki</label>
                    <button onClick={e => move(index, true)}>^</button>
                    <button onClick={e => move(index, false)}>v</button>
                    <button onClick={e => remove(index)}>Usuń</button>
                    <button onClick={e => addEventProperty(index)}>Dodaj właściwość</button>
                    {ev.property !== undefined && ev.property.map((prop: EventProperty[], propIndex: number) => <PropertyRow key={"prop_" + index + "_" + propIndex}>
                        <button onClick={e => removeProperty(index, propIndex)}>Usuń właściwość</button>
                        {ev.property[propIndex].map((propVal: EventProperty, propValueIndex: number) => <React.Fragment key={"propValue_" + index + "_" + propIndex + "_" + propValueIndex}>
                            <input value={propVal.name} onChange={e => changePropertyValue(index, propIndex, propValueIndex, e.target.value)} size={Math.max(3, propVal.name.length)}/>
                        </React.Fragment>)}
                        <button onClick={e => moreProps(index, propIndex)}>+</button>
                        <button onClick={e => lessProps(index, propIndex)}>-</button>
                    </PropertyRow>)}
                </ActionElement>}
            </React.Fragment>)}
        </div>
        <p></p>
        <div>
            <button onClick={e => addEvent(createEvent())}>Stwórz nową akcję</button>
        </div>
    </div>
}
EditEvents.VIEW_NAME = "EditEvents";
export default EditEvents;

const ActionElement = styled.div`
    padding-top: 20px;
`
const PropertyRow = styled.div`
    padding-left: 20px;
`