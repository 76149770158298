import './App.css';
import { StatsProvider } from './StatsContext';
import MainPage from './component/mainPage';
import { QueryClient, QueryClientProvider } from 'react-query'
import { BrowserRouter } from 'react-router-dom';

const queryClient = new QueryClient()

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <StatsProvider>
        <BrowserRouter>
            <MainPage></MainPage>
        </BrowserRouter>
      </StatsProvider>
    </QueryClientProvider>
  );
}

export default App;

export const classNames = (classes: any) => {
  return Object.entries(classes)
    .filter(([_, value]) => value)
    .map(([key, _]) => key)
    .join(' ');
}




