function WarunkiKorzystania() {
    return <div>Data wejścia w życie: 25 pazdziernika 2024
    <br></br>Warunki korzystania z aplikacji "Game statistics":
    <br></br>
    <br></br>Akceptacja warunków korzystania: Korzystajac z aplikacji Game statistics, zgadzasz sie na przestrzeganie i akceptacje niniejszych warunków korzystania.
    <br></br>
    <br></br>Wiek uzytkownika: Aplikacja Game statistics jest przeznaczona wylacznie dla uzytkowników powyzej 18. roku zycia. Jesli jestes osoba niepelnoletnia, musisz uzyskac zgode rodzica lub opiekuna prawnego, aby korzystac z aplikacji.
    <br></br>
    <br></br>Prawa autorskie i wlasnosc intelektualna: Wszystkie tresci i materialy zawarte w aplikacji, takie jak teksty, grafiki, zdjecia, filmy, dzwieki, oprogramowanie i inne, podlegaja prawom autorskim i innym przepisom dotyczacym wlasnosci intelektualnej. Uzytkownik nie ma prawa kopiowac, modyfikowac, rozpowszechniac ani wykorzystywac tych tresci w sposób naruszajacy prawa autorskie i prawa wlasnosci intelektualnej.
    <br></br>
    <br></br>Rejestracja i dane uzytkownika: Podczas rejestracji i korzystania z aplikacji Game statistics, uzytkownik moze byc zobowiazany do podania okreslonych informacji, takich jak imie, nazwisko, adres e-mail itp. Uzytkownik zgadza sie na przekazywanie prawdziwych i dokladnych informacji oraz na odpowiedzialnosc za ochrone swojego konta i hasla.
    <br></br>
    <br></br>Ochrona prywatnosci: Aplikacja Game statistics gromadzi, przechowuje i przetwarza dane osobowe uzytkowników zgodnie z nasza polityka prywatnosci. Uzytkownik zobowiazuje sie do zapoznania sie z ta polityka i akceptuje jej postanowienia.
    <br></br>
    <br></br>Zakaz dzialan nielegalnych i nieetycznych: Uzytkownik zobowiazuje sie do niekorzystania z aplikacji Game statistics w celu prowadzenia dzialan nielegalnych lub nieetycznych, takich jak spamowanie, naruszanie prywatnosci innych uzytkowników, publikowanie tresci obrazliwych lub niezgodnych z prawem, oraz wszelkich innych dzialan niezgodnych z prawem.
    <br></br>
    <br></br>Odpowiedzialnosc: Autor Game statistics nie ponosi odpowiedzialnosci za szkody wynikle z korzystania z aplikacji, w tym utrate danych, straty finansowe lub szkody spowodowane przez innych uzytkowników. Uzytkownik korzysta z aplikacji na wlasne ryzyko.
    <br></br>
    <br></br>Zmiany w warunkach korzystania: Aplikacja Game statistics zastrzega sobie prawo do zmiany warunków korzystania w dowolnym momencie. Zaktualizowane warunki zostana opublikowane w aplikacji, a korzystanie z aplikacji po wprowadzeniu zmian oznacza akceptacje nowych warunków.
    <br></br>
    <br></br>Zakonczenie korzystania z aplikacji: Uzytkownik ma prawo zakonczyc korzystanie z aplikacji w dowolnym momencie. Aplikacja Game statistics równiez moze zakonczyc dostep do uslug uzytkownikowi bez podania przyczyny.
    <br></br>
    <br></br>Kontakt: W razie pytan lub uwag dotyczacych warunków korzystania, uzytkownik moze skontaktowac sie z nami pod adresem parvuselephantus &lt;malpa&gt; gmail.com
    </div>
}
WarunkiKorzystania.VIEW_NAME = "WarunkiKorzystania";
export default WarunkiKorzystania;