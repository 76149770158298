import React from "react";
import StatsContext, { StatsContextType } from "../StatsContext";
import { Player } from "../type/Player";
import styled from "styled-components";

let DivCenter = styled.div`
    text-align: center;
`

function EditPlayers() {
    const props = React.useContext(StatsContext) as StatsContextType;

    const inputValue = (p: Player, playerNo: number, fieldName: string) => {
        return <input value={(p as any)[fieldName] ?? ""} onChange={e => props.changePlayer(playerNo, e.target.value, fieldName)}></input>
    }

    const checkboxValue = (p: Player, playerNo: number, fieldName: string) => {
        return <input type="checkbox" checked={(p as any)[fieldName] ?? true} onChange={e => props.changePlayer(playerNo, e.target.checked, fieldName)}></input>
    }

    return <div>
        <table className="gameView">
            <thead>
                <tr>
                    <th></th>
                    <th>Obecny</th>
                    <th>Numer w Excel'u</th>
                    <th>Numer na koszulce</th>
                    <th>Imię</th>
                    <th>Nazwisko</th>
                    <th>Pozycja</th>
                </tr>
            </thead>
            <tbody id="mainTable">
                {props.players.map((p: Player, playerNo: number) => 
                    <tr key={"player" + playerNo}>
                        <td>
                            <button onClick={e => props.deletePlayer(playerNo)}>Usuń</button>
                            <button onClick={e => props.movePlayer(playerNo, true)}>^</button>
                            <button onClick={e => props.movePlayer(playerNo, false)}>v</button>                        
                        </td>
                        <td>{checkboxValue(p, playerNo, "present")}</td>
                        <td>{inputValue(p, playerNo, "excelNo")}</td>
                        <td>{inputValue(p, playerNo, "shirtNo")}</td>
                        <td>{inputValue(p, playerNo, "name")}</td>
                        <td>{inputValue(p, playerNo, "familyName")}</td>
                        <td>{inputValue(p, playerNo, "pozycja")}</td>
                    </tr>)}
                <tr><td colSpan={6}><button onClick={e => props.addPlayer()}>Dodaj nowego zawodnika</button></td></tr>
            </tbody>
        </table>
    </div>
}
EditPlayers.VIEW_NAME = "EditPlayers";
export default EditPlayers;