import styled from "styled-components";
import { StatisticsReport } from "../../type/Report";
import React from "react";

interface ReportDefinitionParams {
    report: StatisticsReport,
    setReport: (report: StatisticsReport) => void,
    refresh: boolean
}
function ReportDefinition({report, setReport, refresh}: ReportDefinitionParams) {
    const [isWrong, setWrong] = React.useState<boolean>(false);
    const [definition, setDefinition] = React.useState<string>("");

    React.useEffect(() => {
        setDefinition(JSON.stringify(report, null, '\t'));
    }, [refresh]);

    const parseReportDefinition = (txtValue: string) => {
        setDefinition(txtValue);
        try {
            let rep = JSON.parse(txtValue);
            if (typeof rep.name === typeof rep.name
                    && typeof rep.order === typeof rep.order
                    && typeof rep.filter === typeof rep.filter
                    && typeof rep.columns === typeof rep.columns) {
                setReport(rep);
            } else 
                throw new Error("parsed wrong type");
            if (isWrong) setWrong(false);
        } catch (e) {
            if (!isWrong) setWrong(true);
        }
    }
    return <DivCenter>
        <textarea className={isWrong ? "wrong" : ""} value={definition} onChange={e => parseReportDefinition(e.target.value)}></textarea>
    </DivCenter>
}
export default ReportDefinition;

export const simpleViewDefinition = {
	"name": "Prosty widok",
	"order": "tshirt()",
	"filter": "",
	"columns": [
		{
			"formula": "player(\"Imię\")",
			"header": "Zawodnik"
		},
		{
			"formula": "noOf(\"Rzut\")",
			"header": "Rzutów"
		},
		{
			"formula": "noOf(\"Rzut\", \"+Bramka\")",
			"header": "Bramek"
		},
		{
			"formula": "noOf(\"Przechwyt\")",
			"header": "Przechwyty"
		},
		{
			"formula": "noOf(\"Błąd/Strata\")",
			"header": "Straty"
		}
	]
}

export const extendedViewDefinition = {
	"name": "Widok rozszerzony",
	"order": "tshirt(\"Numer w Excel'u\")",
	"filter": "player(\"Pozycja\") != \"Bramkarz\"",
	"columns": [
		{
			"formula": "tshirt(\"Numer w Excel'u\")",
			"header": ""
		},
		{
			"formula": "player(\"Imię\")",
			"header": "Imię"
		},
		{
			"formula": "player(\"Nazwisko\")",
			"header": "Nazwisko"
		},
		{
			"formula": "noOf(\"Rzut\", \"-Karny\")",
			"header": "Rzuty"
		},
		{
			"formula": "noOf(\"Rzut\", \"+Bramka\", \"-Karny\")",
			"header": "Bramki"
		},
		{
			"formula": "concat(100*noOf(\"Rzut\", \"+Bramka\", \"-Karny\") / noOf(\"Rzut\", \"-Karny\"), \"%\")",
			"header": "Skuteczność"
		},
		{
			"formula": "noOf(\"Błąd/Strata\")",
			"header": "Straty/błędy"
		},
		{
			"formula": "noOf(\"Przechwyt\")",
			"header": "Przechwyt"
		},
		{
			"formula": "noOf(\"Blok\")",
			"header": "Blok"
		},
		{
			"formula": "noOf(\"Asysta\")",
			"header": "Asysta"
		},
		{
			"formula": "noOf(\"Kara\", \"+2min\")",
			"header": "Kara 2min"
		},
		{
			"formula": "noOf(\"Rzut\", \"+Karny\")",
			"header": "Rzut karny"
		},
		{
			"formula": "noOf(\"Rzut\", \"+Bramka\", \"+Karny\")",
			"header": "Trafiony k"
		}
	]
}

export const goalKeepersViewDefinition = {
	"name": "Widok bramkarzy",
	"order": "tshirt(\"Numer w Excel'u\")",
	"filter": "player(\"Pozycja\") == \"Bramkarz\"",
	"columns": [
		{
			"formula": "tshirt(\"Numer w Excel'u\")",
			"header": ""
		},
		{
			"formula": "player(\"Imię\")",
			"header": "Imię"
		},
		{
			"formula": "player(\"Nazwisko\")",
			"header": "Nazwisko"
		},
		{
			"formula": "noOf(\"Rzut\", \"-Karny\", \"firstHalf\")",
			"header": "Rzuty"
		},
		{
			"formula": "noOf(\"Rzut\", \"-Bramka\", \"-Karny\", \"firstHalf\")",
			"header": "Obrona"
		},
		{
			"formula": "concat(100*noOf(\"Rzut\", \"-Bramka\", \"-Karny\", \"firstHalf\") / noOf(\"Rzut\", \"-Karny\", \"firstHalf\"), \"%\")",
			"header": "Skuteczność"
		},
		{
			"formula": "noOf(\"Rzut\", \"+Karny\", \"firstHalf\")",
			"header": "Rzut karny"
		},
		{
			"formula": "noOf(\"Rzut\", \"-Bramka\", \"+Karny\", \"firstHalf\")",
			"header": "o. rzut k"
		},
		{
			"formula": "concat(100*noOf(\"Rzut\", \"-Bramka\", \"+Karny\", \"firstHalf\") / noOf(\"Rzut\", \"+Karny\", \"firstHalf\"), \"%\")",
			"header": "% o. karny"
		},
		{
			"formula": "noOf(\"Asysta\", \"firstHalf\")",
			"header": "Asysta"
		},
		{
			"formula": "noOf(\"Kara\", \"+Błąd/Strata\", \"firstHalf\")",
			"header": "Straty"
		},
		{
			"formula": "noOf(\"Rzut\", \"-Karny\", \"secondHalf\")",
			"header": "Rzuty"
		},
		{
			"formula": "noOf(\"Rzut\", \"-Bramka\", \"-Karny\", \"secondHalf\")",
			"header": "Obrona"
		},
		{
			"formula": "concat(100*noOf(\"Rzut\", \"-Bramka\", \"-Karny\", \"secondHalf\") / noOf(\"Rzut\", \"-Karny\", \"secondHalf\"), \"%\")",
			"header": "Skuteczność"
		},
		{
			"formula": "noOf(\"Rzut\", \"+Karny\", \"secondHalf\")",
			"header": "Rzut karny"
		},
		{
			"formula": "noOf(\"Rzut\", \"-Bramka\", \"+Karny\", \"secondHalf\")",
			"header": "o. rzut k"
		},
		{
			"formula": "concat(100*noOf(\"Rzut\", \"-Bramka\", \"+Karny\", \"secondHalf\") / noOf(\"Rzut\", \"+Karny\", \"secondHalf\"), \"%\")",
			"header": "% o. karny"
		},
		{
			"formula": "noOf(\"Asysta\", \"secondHalf\")",
			"header": "Asysta"
		},
		{
			"formula": "noOf(\"Kara\", \"+Błąd/Strata\", \"secondHalf\")",
			"header": "Straty"
		}
	]
}

const DivCenter = styled.div`
    text-align: center;

    textarea {
        background: white;
        width: 80%;
        margin: 20px;
        height: 300px;
    }

    @media (max-width: 600px) {
        textarea {
            width: 100%;
            margin: 0px;
        }
      }

    textarea.wrong {
        background: lightred;
    }
`