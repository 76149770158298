import React from "react";
import StatsContext, { StatsContextType } from "../../../StatsContext";
import '../GameView.css';
import { Player, getPlayerString } from "../../../type/Player";
import EventsColumn from "./EventsColumn";
import StatisticsColumn from "./StatisticsColumn";

interface PlayerProps {
    player: Player,
    playerId: string
}
function PlayerRow({player, playerId}: PlayerProps) {
    const props = React.useContext(StatsContext) as StatsContextType;
   
    let clazzName = (player.onField?? false) ? "" : "benchy";
    return <tr className={clazzName}>
        <td>
			<div className="propertyList">
                {getPlayerString(player)}
			</div>
        </td>
        <EventsColumn isWholeTeamColumn={false} player={player} playerId={playerId}></EventsColumn>
		<StatisticsColumn isWholeTeamColumn={false} playerId={playerId}></StatisticsColumn>
    </tr>
}
export default PlayerRow;