import React from "react";
import StatsContext, { StatsContextType } from "../StatsContext";
import EditEvents from "./editEvents/EditEvents";
import Header from "./Header";
import GameView from "./gameView/GameView";
import LoadView from "./LoadView";
import { useSearchParams } from "react-router-dom";
import StatisticsView from "./statistics/StatisticsView";
import EditPlayers from "./EditPlayers";
import Ideas from "./Ideas";
import Footer from "./footer";
import styled from "styled-components";
import PolitykaPrywatnosci from "./consents/polityka_prywatnosci";
import WarunkiKorzystania from "./consents/warunki_korzystania";

function MainPage() {
    const props = React.useContext(StatsContext) as StatsContextType;
    const [searchParams] = useSearchParams();

    const downloadFile = async (fileName: string) => {
        try {
            const response = await fetch(process.env.REACT_APP_BE_SERVER + `/getGame?gameId=${encodeURIComponent(fileName)}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
        
            if (!response.ok) {
                throw new Error(`Failed to download file: ${response.statusText}`);
            }
        
            const jsonContent = await response.json();
            props.loadFile(jsonContent, false);
        } catch (error: any) {
            console.error('Error downloading file:', error.message);
        }
    }

    React.useEffect(() => {
        let game = searchParams.get("game");
        if (game != undefined) {
            downloadFile(game).then(() => {
                props.setCurrentModalView(null);
                props.setCurrentView(StatisticsView.VIEW_NAME); 
            });
        }
    }, []);
    
    return <MainPageDiv>
        <Header></Header>
        <MainBodyDiv>
            {props.currentModalView === null && <>
                {props.currentView === EditEvents.VIEW_NAME && <EditEvents></EditEvents>}
                {props.currentView === EditPlayers.VIEW_NAME && <EditPlayers></EditPlayers>}
                {props.currentView === GameView.VIEW_NAME && <GameView></GameView>}
                {props.currentView === LoadView.VIEW_NAME && <LoadView></LoadView>}
                {props.currentView === StatisticsView.VIEW_NAME && <StatisticsView></StatisticsView>}
                {props.currentView === Ideas.VIEW_NAME && <Ideas></Ideas>}
                {props.currentView === PolitykaPrywatnosci.VIEW_NAME && <PolitykaPrywatnosci></PolitykaPrywatnosci>}
                {props.currentView === WarunkiKorzystania.VIEW_NAME && <WarunkiKorzystania></WarunkiKorzystania>}
            </>}
            {props.currentModalView !== null && <>
                {props.currentModalView === LoadView.VIEW_NAME && <LoadView></LoadView>}
            </>}
        </MainBodyDiv>
        {props.currentView != GameView.VIEW_NAME && <Footer></Footer>}
    </MainPageDiv>
}


const MainBodyDiv = styled.div`
    flex: 1;
`

const MainPageDiv = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    margin: 0;
    width: 100%
`

export default MainPage;

