import React from "react";
import styled from "styled-components"
import StatsContext, { StatsContextType } from "../StatsContext";
import PolitykaPrywatnosci from "./consents/polityka_prywatnosci";
import WarunkiKorzystania from "./consents/warunki_korzystania";

function Footer() {
    const props = React.useContext(StatsContext) as StatsContextType;

    return <div>
        <hr></hr>
        <FooterStrapDiv>
            {/* <a href={process.env.PUBLIC_URL + "/assets/consents/polityka_prywatnosci.txt"}>Polityka prywatności */}
            <ButtonToLink onClick={e => props.setCurrentView(PolitykaPrywatnosci.VIEW_NAME)}>Polityka prywatności
            </ButtonToLink> <ButtonToLink onClick={e => props.setCurrentView(WarunkiKorzystania.VIEW_NAME)}>Warunki korzystania
            </ButtonToLink>
        </FooterStrapDiv>
    </div>
}

const FooterStrapDiv = styled.div`
    width: 100%;
    padding-bottom: 10px;
    text-align: center;
`

const ButtonToLink = styled.button`
    fontSize: "1em";
    textAlign: "left";
    color: 'blue';
    background: 'none';
    margin: 0;
    padding: 0;
    border: 'none';
    cursor: 'pointer;
`

export default Footer;