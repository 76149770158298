
function PolitykaPrywatnosci() {
    return <div>Data wejścia w życie: 25 pazdziernika 2024
<br></br>
<br></br>Dziękujemy za korzystanie z naszej aplikacji. Prosimy o dokładne przeczytanie i zrozumienie poniższej Polityki Prywatnosci i Warunków Użytkowania przed rozpoczęciem korzystania z aplikacji. Korzystąjac z tej aplikacji, wyrażasz zgodę na postanowienia zawarte w niniejszym dokumencie. Jeśli nie zgadzasz się z tymi warunkami, nie korzystaj z naszej aplikacji.
<br></br>
<br></br>1. Gromadzenie danych osobowych:
<br></br>
<br></br>Nasza aplikacja gromadzi pewne dane osobowe od użytkowników, w tym imię, nazwisko oraz numer na koszulce zawodników. Dodatkowo, do celów identyfikacji użytkowników podczas logowania, używamy adresu e-mail jako identyfikatora. Wszystkie te dane są przechowywane na serwerach hostowanych przez firmę OVH, zapewniającą wysoki poziom bezpieczeństwa.
<br></br>
<br></br>2. Przetwarzanie danych:
<br></br>
<br></br>Zbierane dane osobowe są przetwarzane wyłącznie w celu świadczenia usług związanych z naszą aplikacją. Nie udostępniamy tych informacji osobom trzecim bez wyraźnej zgody użytkownika, chyba że jest to wymagane przez obowiązujące prawo.
<br></br>
<br></br>3. Bezpieczeństwo danych:
<br></br>
<br></br>Dokładamy wszelkich starań, aby zabezpieczyć gromadzone dane osobowe przed nieautoryzowanym dostępem, utratą, używaniem, modyfikacją lub ujawnieniem. Nasze serwery są regularnie monitorowane i aktualizowane, aby zapewnić optymalne środowisko bezpieczeństwa.
<br></br>
<br></br>4. Udostępnianie danych:
<br></br>
<br></br>Zobowiązujemy się do nieudostępniania, sprzedaży ani przekazywania w jakikolwiek sposób zebranych danych osobowych osobom trzecim bez wyraźnej zgody użytkownika, z wyjątkiem sytuacji wymaganych przez prawo.
<br></br>
<br></br>5. Odpowiedzialność za wprowadzone dane:
<br></br>
<br></br>Zobowiązujemy użytkowników do udostępniania danych jedynie osobom do tego uprawnionym. Nie bierzemy odpowiedzialności za udostępnienie danych przez użytkownika osobom trzecim. Wprowadzając dane do programu, ponosisz odpowiedzialność za ich nie rozprzestrzenianie do osób niepowołanych.
<br></br>
<br></br>6. Korzystanie na własna Odpowiedzialność
<br></br>
<br></br>Korzystanie z naszej aplikacji odbywa sie wyłacznie na własną odpowiedzialność użytkownika. Nie ponosimy odpowiedzialności za jakiekolwiek straty, szkody, błędy, czy problemy wynikajace z korzystania z naszej aplikacji. Aplikacja służy do wyliczania statystyk na potrzeby własne drużyny sportowej. Użytkownik ponosi pełna odpowiedzialność za wszelkie konsekwencje zwiazane z korzystaniem z aplikacji wynikłe wbrew przeznaczeniu aplikacji w tym udzielenie danych osobom niepowołanym.
<br></br>
<br></br>7. Zgoda na Zasady Prywatności
<br></br>
<br></br>Korzystając z naszej aplikacji oraz wprowadzając dane do niej, użytkownik wyraza zgodę na postanowienia Polityki Prywatnosci i Warunków Użytkowania. Jednocześnie użytkownik wprowadzając dane zawodników oświadcza, że posiada zgodę na przetwarzanie ich danych i wykorzystywanie w niniejszym programie.
<br></br>
<br></br>8. Zmiany w Polityce Prywatnosci
<br></br>
<br></br>Możemy aktualizować naszą Politykę Prywatności i Warunki Użytkowania. Wszelkie zmiany zostaną opublikowane na naszej stronie internetowej, a data wejścia w życie zostanie zaktualizowana. Użytkownik jest zobowiązany do regularnego sprawdzania naszej Polityki Prywatnosci w celu zapoznania sie z ewentualnymi zmianami. W przypadku zostawienia kontaktu w postaci adresu mailowego zostanie on o wszelkiej zmianie powiadomiony z conajmniej miesięcznym wyprzedzeniem.
<br></br>
<br></br>9. Prawo do usunięcia danych
<br></br>
<br></br>Użytkownik ma prawo do usunięcia dowolnych wprowadzonych danych przez siebie - również danych osobowych.
<br></br>
<br></br>
<br></br>Pytania lub uwagi dotyczące naszej Polityki Prywatności i Warunków Uzytkowania, prosimy kierować pod adresem parvuselephantus &lt;malpa&gt; gmail.com.
<br></br>
<br></br>Dziękujemy za korzystanie z naszej aplikacji i życzymy udanego korzystania z niej!
<br></br>
<br></br>25 stycznia 2024
</div>
}
PolitykaPrywatnosci.VIEW_NAME = "PolitykaPrywatnosci";
export default PolitykaPrywatnosci;