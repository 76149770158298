import React from "react";
import StatsContext, { StatsContextType } from "../../../StatsContext";
import '../GameView.css';
import { Player } from "../../../type/Player";
import { UserAction, actionEntry } from "../../../type/UserAction";

interface StatisticsColumnProps {
    isWholeTeamColumn: boolean,
    playerId?: string,
}
function StatisticsColumn({isWholeTeamColumn, playerId}: StatisticsColumnProps) {
    const props = React.useContext(StatsContext) as StatsContextType;
	const [showStatistics, setShowStatistics] = React.useState<boolean>(false);
    
	const getLastActions = (): UserAction[] => {
        let ret: UserAction[] = [];
        if (props.matchStart === null) {
            return ret;
        }
        for (let i = props.actions.length-1; ret.length < 5 && i >= 0; i--) {
            let action: UserAction = props.actions[i];
            if (!action.time.isBefore(props.matchStart) && (
                    (isWholeTeamColumn && action.actionOwner === "team")
                 || (!isWholeTeamColumn && action.playerId === playerId))) {
                ret.push(action);
            }
        }
        return ret;
	}

	const hasActionsToShow = (): boolean => {
        if (props.matchStart === null) {
            return false;
        }

		for (let i = 0; i < props.actions.length; i++) {
			if (!props.actions[i].time.isBefore(props.matchStart)) {
				return true;
			}
		}
		return false;
	}

    return <td>
        <button onClick={e => setShowStatistics(!showStatistics)} disabled={!hasActionsToShow()}>{showStatistics ? "Ukryj" : "Pokaż"}</button>
        {showStatistics && <>
            <br></br>
            {getLastActions().map((action, actionNo) => <div key={"statRow_" + playerId + "_" + actionNo}>
                {actionEntry(props, action)}
                <button onClick={e => props.deleteAction(-actionNo)}>X</button>
            </div>)}
        </>}
    </td>
}
export default StatisticsColumn;