import { useMutation } from 'react-query';

export interface FileUploadParameters {
    jsonData: string,
    gameId: string
}
const FileUploading = () => {
  const { mutate, isLoading } = useMutation(async ({jsonData, gameId}: FileUploadParameters) => {
    const blob = new Blob([jsonData], { type: 'application/json' });
    const file = new File([blob], gameId + ".json", { type: 'application/json' });

    const formData = new FormData();
    formData.append('file', file);

    const response = await fetch(process.env.REACT_APP_BE_SERVER + '/setGame', {
      method: 'POST',
      body: formData,
    });

    if (!response.ok) {
      throw new Error('File upload failed');
    }
    return response;
  });

  return {uploadFile: mutate, isUploading: isLoading}
};

export default FileUploading;
