import dayjs, { Dayjs } from "dayjs";
import { StatsContextType } from "../StatsContext";

export type UserAction = {
    time: Dayjs,
    name: string,
    properties: any,
    /**
     * "team" or "player"
     */
    actionOwner?: string,
    //playerNo: number; //-1 for team,  >= for no of user in players array (see context)
    playerId: string,

    //player?: string,
}

export const getUserActionTime = (props: StatsContextType, action: UserAction): string => {
    if (props.matchStart == null) return "0:00";
    var diffMs = action.time.diff(props.matchStart, "millisecond");
    diffMs = Math.max(0, diffMs);
    var minutes = Math.floor(diffMs / (1000 * 60)); // minutes
    var seconds = Math.floor((diffMs/1000) - minutes*60);
    return minutes + ":" + seconds;
}
export const getUserActionProperties = (action: UserAction): string => {
    let ret: string = "";
    let firstFound: boolean = false;
    if (action.properties !== undefined) {
        for (const propKey in action.properties) {
            if (action.properties.hasOwnProperty(propKey) && action.properties[propKey] !== undefined) {
                if (!firstFound) {
                    ret += "(" + action.properties[propKey];
                    firstFound = true;
                } else {
                    ret += ", " + action.properties[propKey];
                }
            }
        }
        if (firstFound) {
            ret += ")";
        }
    }
    return ret;
}

export const actionEntry = (props: StatsContextType, action: UserAction): string => {
    let ret: string = getUserActionTime(props, action) + " " + action.name;
    ret += getUserActionProperties(action);
    return ret;
}

export const createUserAction = (actionName: string, playerId: string, properties?: any) => {
    let action: UserAction = {
        time: dayjs(),
        name: actionName,
        properties: properties ?? {},
        playerId: playerId,
        actionOwner: playerId == "team" ? "team" : "player"
    };
    return action;
}