import React from "react";
import StatsContext, { StatsContextType } from "../StatsContext";
import dayjs from "dayjs";
import EditEvents from "./editEvents/EditEvents";
import GameView from "./gameView/GameView";
import LoadView from "./LoadView";
import './Header.css';
import StatisticsView from "./statistics/StatisticsView";
import EditPlayers from "./EditPlayers";
import Login from "../Login";

function Header() {
    const props = React.useContext(StatsContext) as StatsContextType;

    return <div>
        <span className="littleSpace"></span>
        <button onClick={e => props.setCurrentView(LoadView.VIEW_NAME)}>Menu</button>
        <span className="littleSpace"></span>

        <button className={props.currentView === EditEvents.VIEW_NAME ? "selected" : ""} onClick={e => props.setCurrentView(EditEvents.VIEW_NAME)}>Akcje</button>
        <button className={props.currentView === EditPlayers.VIEW_NAME ? "selected" : ""} onClick={e => props.setCurrentView(EditPlayers.VIEW_NAME)}>Zawodnicy</button>
        <button className={props.currentView === GameView.VIEW_NAME ? "selected" : ""} onClick={e => props.setCurrentView(GameView.VIEW_NAME)}>Widok gry</button>
        <button className={props.currentView === StatisticsView.VIEW_NAME ? "selected" : ""} onClick={e => props.setCurrentView(StatisticsView.VIEW_NAME)}>Statystyki</button>

        {props.currentView === GameView.VIEW_NAME && <span className="headerCenter">
            <span className="littleSpace"></span>
            {props.matchStart === null && <button id="matchStart" onClick={e => props.setMatchStart(dayjs())}>Początek meczu</button>}
            {props.firstHalfEnd === null && props.matchStart !== null && <button id="halfEnd" onClick={e => {props.setFirstHalfEnd(dayjs())}}>Koniec I połowy</button>}
            {props.secondHalfStart === null && props.firstHalfEnd !== null && <button id="sndHalfStart" onClick={e => props.setSecondHalfStart(dayjs())}>Początek II polowy</button>}
            {props.matchEnd === null && props.secondHalfStart !== null && <button id="matchEnd" onClick={e => props.setMatchEnd(dayjs())}>Koniec meczu</button>}
        </span>}
        
        {props.currentView === GameView.VIEW_NAME && <span className="headerRight">
            <button onClick={e => props.setGameView("perPlayer")}>View per player</button>
            <button onClick={e => props.setGameView("static")}>Static view</button>
            <span className="littleSpace"></span>
        </span>}
        {/* <Login></Login> */}
        {/* <AudioInput></AudioInput> */}
    </div>
}
export default Header;