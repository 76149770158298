import { StatsContextType } from "../StatsContext"
import { EventProperty } from "./EventProperty"

export type Event = {
    name: string,
    firstHalf: boolean,
    secondHalf: boolean,
    teamLevel?: boolean
    isEntry?: boolean,
    isExit?: boolean,
    property: EventProperty[][],
    pass?: string,
    getTheBall: boolean,
    looseTheBall: boolean
}

export const getEventDefinition = (props: StatsContextType, name: string): Event|undefined => {
    for (let i = 0; i < props.events.length; i++) {
        if (props.events[i].name === name) {
            return props.events[i];
        }
    }
    return undefined;
}

export const createEvent = (): Event => {
    let event: Event = {
        name: "",
        firstHalf: true,
        secondHalf: true,
        property: [],
        getTheBall: false,
        looseTheBall: false,
    };
    return event;
}