import React from "react";
import StatsContext, { StatsContextType } from "../../StatsContext";
import { Player } from "../../type/Player";
import { classNames } from "../../App";
import { PASS_GET_EVENT, PASS_ON_EVENT } from "../editEvents/PassEventEdit";
import { UserAction, createUserAction } from "../../type/UserAction";
import dayjs from "dayjs";

interface BallPassActionParams {
    player: Player,
    tryAddingAction: (p: Player) => void,
}
function BallPassAction({player, tryAddingAction}: BallPassActionParams) {
    const props = React.useContext(StatsContext) as StatsContextType;

    const passTheBall = () => {
        let prevPlayerId = props.ballOwnerId;
        if (prevPlayerId !== player.id) {
            props.setBallOwnerId(player.id);

            if (prevPlayerId != undefined) {
                let evs = [...props.actions];

                let evsPassOn = props.events.filter(ev => ev.pass === PASS_ON_EVENT);
                let actionPassOn: UserAction|undefined = undefined;
                if (evsPassOn.length > 0) {
                    actionPassOn = createUserAction(evsPassOn[0].name, prevPlayerId);
                    evs.push(actionPassOn);
                }

                let evGet = props.events.filter(ev => ev.pass === PASS_GET_EVENT);
                if (evGet.length > 0) {
                    let action = createUserAction(evGet[0].name, player.id);
                    if (actionPassOn != undefined && action.time.isSame(actionPassOn!.time)) {
                        action.time = action.time.add(1, 'ms');
                    }
                    evs.push(action);
                }

                // console.log(evs.map(ev => ev.name + " " + props.getPlayer(ev.playerId)?.name));

                props.setActions(evs);
            } else {
                tryAddingAction(player);
            }
        } else {
            props.setBallOwnerId(undefined);
        }
    }

    return <button onClick={e => passTheBall()}
                className={classNames({"selected": player.id === props.ballOwnerId, withImage:true})}
            >
        <img src={process.env.PUBLIC_URL + '/assets/ball.png'}></img>
    </button>
}
export default BallPassAction;