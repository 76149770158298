import { UserAction } from "./UserAction";

export type Player = {
    name: string,
    id: string,
    present: boolean,
    familyName?: string,
    shirtNo?: number,
    excelNo?: number,//Numer w excelu trenera
    onField?: boolean,
    pozycja?: string,
}

export function getPlayerString(p: Player):string {
    return (p.shirtNo == undefined ? "" : p.shirtNo + " ") + p.name + " " + (p.familyName ?? "")
}